import React from 'react'
import '../Css/SectionCourses.css'
import hair from "../Asserts/imagewebp/PR(27).webp";
import bridal from "../Asserts/imagewebp/PR(28).webp";
import beutician from "../Asserts/images/pm (5).webp";
import { Link } from 'react-router-dom';
const SectionCourses = () => {
  return (
    <>
        <section className="services section-padding pb-0">
  <div className="container" bis_skin_checked={1}>
    <div className="title" bis_skin_checked={1}>
      <div className="row" bis_skin_checked={1}>
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-xs-12"
          bis_skin_checked={1}
        >
          <div className="sec-title text-left" bis_skin_checked={1}>
            <h1>Our Courses</h1>
          </div>
        </div>
        <div
          className="col-xl-6 col-lg-6 col-md-6 col-xs-12"
          bis_skin_checked={1}
        >
          <div className="title-text text-left" bis_skin_checked={1}>
            <p>
            From fundamental techniques to cutting-edge trends, our curriculum empowers you with the skills and knowledge needed to thrive in the dynamic salon industry. Led by industry experts, our hands-on approach ensures a personalized learning experience, paving the way for your success. Join us and unlock your potential to redefine beauty in our Makeover Academy in Andheri.</p>
          </div>
        </div>
      </div>
    </div>
    <div className="row" bis_skin_checked={1}>
      <div
        className="col-xl-6 col-lg-6 col-md-6 col-xs-12 my-2"
        bis_skin_checked={1}
      >
        <div
          className="image-box img-hover-zoom--zoom-n-pan-v"
          bis_skin_checked={1}
        >
          <img src='https://img.freepik.com/free-photo/attractive-woman-getting-face-beauty-procedures-spa-salon_1098-18097.jpg' alt=' Academy In Andheri' />
          <div className="text" bis_skin_checked={1}>
            <h2> BEAUTICIAN</h2>
            <Link to='/beautician-course-andheri'>
            <a
              href="/beautician-course-andheri"
              className="theme-btn-white-border btn-md"
            >
              view detail
            </a>
            </Link>
            
          </div>
        </div>
      </div>
      <div
        className="col-xl-6 col-lg-6 col-md-6 col-xs-12  my-2"
        bis_skin_checked={1}
      >
        <div
          className="image-box img-hover-zoom--zoom-n-pan-v"
          bis_skin_checked={1}
        >
          <img src={hair} alt=' Academy In Andheri' />
          <div className="text" bis_skin_checked={1}>
            <h2 className="">hair</h2>
            <Link to='/hair-dressing-course-andheri'>
            <a
              href="/hair-dressing-course-andheri"
              className="theme-btn-white-border btn-md"
            >
              view detail
            </a>
            </Link>
          </div>
        </div>
      </div>
      <div
        className="col-xl-6 col-lg-6 col-md-6 col-xs-12 my-2"
        bis_skin_checked={1}
      >
        <div
          className="image-box img-hover-zoom--zoom-n-pan-v"
          bis_skin_checked={1}
        >
          <img src={bridal} alt='/Makeover Academy' />
          <div className="text" bis_skin_checked={1}>
            <h2>BRIDAL</h2>
            <Link to='/bridal-makeover-course-andheri'>
            <a
              href="/bridal-makeover-course-andheri"
              className="theme-btn-white-border btn-md"
            >
              view detail
            </a>
            </Link>
          </div>
        </div>
      </div>
      <div
        className="col-xl-6 col-lg-6 col-md-6 col-xs-12 my-2"
        bis_skin_checked={1}
      >
        <div
          className="image-box img-hover-zoom--zoom-n-pan-v"
          bis_skin_checked={1}
        >
          <img src="https://i.ytimg.com/vi/3CkiiWtoV-Q/maxresdefault.jpg" alt='Makeover  In Andheri' />
          <div className="text" bis_skin_checked={1}>
            <h2>SAREE </h2>
            <Link to='/saree-draping-course-andheri'>
            <a
              href="/saree-draping-course-andheri"
              className="theme-btn-white-border btn-md"
            >
              view detail
            </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default SectionCourses