
import beautician from "../Asserts/images/pm (5).webp";
// import bridal from "../Asserts/images/pm (14).webp";
import saree from "../Asserts/images/pm (9).webp";
import saree1 from "../Asserts/images/pm (7).webp";
import bridal from "../Asserts/imagewebp/PR(28).webp";
import hairDressing from "../Asserts/imagewebp/PR(25).webp";
import nailArts from "../Asserts/webp/PR(23).webp";
import beutitian from "../Asserts/imagewebp/PR(26).webp";

const coursesData = [
    {
        id:1,
        TitleImg:hairDressing,
        courseName:"Hair Dressing ",
        h1:"Hair Dressing Course In Andheri",
        slugs: "/hair-dressing-course-andheri",
        title:"Hair Stylist Course in Andheri | Pratima Makeover",
        seoDesc:"Learn professional hair styling techniques at Pratima Makeover's Hair Stylist Course in Andheri. Enhance your skills in this dynamic industry.",
        description: "Experience the artistry and precision of hairdressing with our comprehensive Hair Dressing Course In Andheri. Our program is designed to equip students with the skills and knowledge needed to excel in the dynamic field of hair care. From mastering the fundamentals of cutting and coloring to learning advanced techniques in texture and styling, our curriculum covers all aspects of modern hairdressing. With a focus on hands-on learning and individualized instruction, our experienced educators provide personalized guidance and support to help students achieve their goals. Whether you're passionate about pursuing a career as a salon stylist, runway artist, or educator, our Hair Dressing Course In Andheri will prepare you for success in the fast-paced world of hairdressing.",
        // CourseTitleTwo : {
        //     img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxihxeLlUTecwBiwgs_AFslshmJmXbBkVbSXdTchbP3pvjfdUHggI97PqkcmyU0VoPctg&usqp=CAU",
        //     Titlename: "Hair Style ",
        //     List:["Crimping","Ironing","Tong","tong With variation","Normal Bun", "Messey Bun","Messy Hairstyle","Front Variation","Advance braid","French Bun","High Ponitail (Unicorn)","Bridal Bun With Variation","Hairstyle with decoration"," messy Ponitail (curls)","Messy Braid","Western Bun",]
        // },
        CourseTitleOne : {
            img:"https://media.npr.org/assets/img/2023/07/13/gettyimages-1245778274_custom-a704bc5b27c6dacc894a36300e64b040c56f36c9-s800-c85.webp",
            Titlename: "Hair Dressing ",
            List:["Hair Cut ","Hair Spa","Dandruff Treatment","Smoothing","Rebonding","Keratin","Botex","Nenoplastia","Global Colour","Root Touch up","Fashion Colour",'Highlight',"balayage Colour","Ombre Colour","Blow Dry",'Ironing']
        },
    },
    {
        id:1,
        TitleImg:"https://www.shutterstock.com/image-photo/young-woman-visit-beauty-salon-600nw-1551548927.jpg",
        courseName:"Hair Styling ",
        h1:"Hair Styling Course In Andheri",
        slugs: "/hair-styling-course-andheri",
        title:"Hair Styling Course In Andheri | Pratima Makeover",
        seoDesc:"Discover expert techniques and trends in our Hair Styling Course in Andheri, led by Pratima Makeover. Elevate your skills today!",
        description: "Our Hair Styling Course In Andheri is designed to ignite your creativity and hone your skills in the art of hairstyling. From mastering the basics of blowouts and curls to creating intricate updos and avant-garde styles, our comprehensive curriculum covers a wide range of techniques and trends. Led by industry experts with years of experience, our hands-on classes provide students with the opportunity to learn and practice in a supportive and collaborative environment. Whether you're a beginner looking to start your journey in hairstyling or a seasoned professional seeking to refine your craft, our Hair Styling Course In Andheri is tailored to meet your needs and help you reach your full potential. Join our Hair Styling Course In Andheri and unleash your creativity as you embark on this exciting adventure in the world of hair styling.",
        CourseTitleOne : {
            img:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSxihxeLlUTecwBiwgs_AFslshmJmXbBkVbSXdTchbP3pvjfdUHggI97PqkcmyU0VoPctg&usqp=CAU",
            Titlename: "Hair Style ",
            List:["Crimping","Ironing","Tong","tong With variation","Normal Bun", "Messey Bun","Messy Hairstyle","Front Variation","Advance braid","French Bun","High Ponitail (Unicorn)","Bridal Bun With Variation","Hairstyle with decoration"," messy Ponitail (curls)","Messy Braid","Western Bun",]
        },
        //  CourseTitleTwo : {
        //     img:"https://media.npr.org/assets/img/2023/07/13/gettyimages-1245778274_custom-a704bc5b27c6dacc894a36300e64b040c56f36c9-s800-c85.webp",
        //     Titlename: "Basic to Advance ",
        //     List:["Hair Cut (Basic to advance)","Hair Spa","Dandruff Treatment","Smoothing","Rebonding","Keratin","Botex","Nenoplastia","Global Colour (Root Touch up)","Fashion Colour (Highlight)","balayage Colour","Ombre Colour"]
        // },
    },
    {
        id:1,
        TitleImg: beutitian,
        courseName:"Beautician ",
        h1:"Beautician Course In Andheri",
        slugs: "/beautician-course-andheri",
        title:"Beautician Course in Andheri | Pratima Makeover",
        seoDesc:"Discover the secrets of beauty at Pratima Makeover's exclusive Beautician Course in Andheri. Elevate your skills in makeup, haircare, and skincare.",
        description: "At our Academy, the Beautician Course In Andheri, our team of skilled beauticians is dedicated to helping clients look and feel their best with a range of personalized beauty services. From luxurious facials and rejuvenating skin care treatments to flawless makeup application and precision waxing, our beauticians offer a comprehensive range of services tailored to meet each client's unique needs and preferences. With a keen eye for detail and a commitment to excellence, our beauticians take pride in delivering exceptional results that exceed expectations. Whether you're preparing for a special occasion, seeking a relaxing pampering session, or simply looking to enhance your natural beauty, our beauticians are here to provide you with the expert care and attention you deserve. Join our Beautician Course In Andheri and experience the transformative power of beauty as our dedicated team of beauticians helps you look and feel your absolute best.",
        CourseTitleOne : {
            img:"https://whitefield-lakmeacademy.in/wp-content/uploads/2023/07/make-up-artist-propose-change-colour-lipstick-young-model-with-long-hair-scaled.jpg",
            Titlename: "Beautician Course",
            List:["Threading","Waxing (Honey)","Rica Wax","Body waxing","Manicure","Pedicure","Bleach","D-tan","Facial","Body Bleach","Body Polishing","Body Massage","Head Massage","Pressure Point Massage","Advance Facial"]
        },
        // CourseTitleTwo : {
        //     Titlename: "Basic to Advance Make-Up",
        //     List:["Self Make-up","Sider Make-up","Party Makeup","bridesmaids make-up", "college or office going makeup", "corporate Make-up"]
        // },
    },
    {
        id:1,
        courseName:"Bridal ",
        TitleImg: bridal,
        h1:"Bridal Makeover Course In Andheri",
        slugs: "/bridal-makeover-course-andheri",
        title:"Bridal Makeover Course in Andheri | Pratima Makeover",
        seoDesc:"Master the art of bridal makeup in Andheri with Pratima Makeover's course. Expert guidance ensures professional skills development.",
        description: "Take your makeup skills to the next level with our comprehensive Basic to Advanced Bridal Makeover Course In Andheri, designed for aspiring artists seeking to master a wide range of techniques and styles. From natural daytime looks to glamorous evening makeup, our program covers it all, providing students with the opportunity to explore different makeup trends and applications. Led by industry professionals with years of experience, our hands-on classes allow students to practice their skills in a supportive and creative environment. Whether you're a beginner looking to build a strong foundation in makeup artistry or a seasoned professional seeking to expand your repertoire, our Bridal Makeover Course In Andheri offers something for everyone. Join us and embark on a journey of creativity and self-expression as you refine your craft and discover the endless possibilities of makeup artistry.",
        CourseTitleOne : {
            img:"https://img.freepik.com/premium-photo/makeup-artist-hands-with-brush-makes-up-apply-shadows-eyes-brides-girl-beauty-salon_118086-3622.jpg?w=740",
            Titlename: "Basic Make-Up",
            List:["Self Make-up","Sider Make-up","Party Makeup","bridesmaids make-up", "college or office going makeup", "corporate Make-up"]
        },
        CourseTitleTwo : {
            img:"https://www.bodycraft.co.in/wp-content/uploads/makeup-process-wedding-day-scaled.jpg",
            Titlename: "Basic to Advance Make-Up",
            List:["Bridal makeup","Maharashtrian Bride","Gujrati bride","Bangoli bride", "South Indian bride","Punjabi bride","Catholic bride","Muslim bride","Reception bride","Coctail Party bride"," Engagement","Sangeet","Haldi Look","Mehndi Look","Complete Theory Knowledge","Hands on practice","Certification",]
        },
    },
    {
        id:1,
        TitleImg: "https://i.ytimg.com/vi/3CkiiWtoV-Q/maxresdefault.jpg",
        courseName:"Saree Draping",
        h1:"Saree Draping Course In Andheri",
        slugs: "/saree-draping-course-andheri",
        title:"Saree Draping Course in Andheri | Pratima Makeover",
        seoDesc:"Learn to drape sarees elegantly with our Saree Draping Course in Andheri by Pratima Makeover. Master the art effortlessly.",
        description: "Experience the timeless elegance of saree draping with our Saree Draping Course In Andheri, where students learn the art of styling this iconic garment with finesse and flair. Our comprehensive program covers a variety of draping styles, from classic to contemporary, allowing students to master the techniques needed to create stunning looks for any occasion. Led by experienced instructors with a passion for tradition and creativity, our hands-on classes provide students with the opportunity to practice and perfect their draping skills in a supportive and collaborative environment. Whether you're a beginner looking to learn the basics of saree draping or a seasoned enthusiast seeking to refine your technique, our Saree Draping Course In Andheri offers something for everyone. Join us and embrace the beauty and versatility of the saree as you learn to drape with confidence and grace.        ",
        CourseTitleOne : {
            img: saree,
            Titlename: "Saree Draping",
            List:["Tarditional Saree Drapping","Bridal Dupatta drapping","Dupatta Drapping with variation"]
        },
        CourseTitleTwo : {
            img: saree1,
            Titlename: "Traditional Saree",
            List:["Navratri style","Bangoli style","Gujarati Style","Normal Saree"]
        },
    },
    {
        id:1,
        TitleImg: nailArts,
        courseName:"Nails ",
        h1:"Nail Art In Andheri",
        slugs: "/nail-art-Andheri",
        title:"Nail Art In Andheri | Pratima Makeover",
        seoDesc:"Explore stunning nail art in Andheri at Pratima Makeover, where creativity meets elegance for flawless nails worth flaunting.",
        description: "Indulge in the latest trends and express your creativity with Nail Art In Andheri. Our skilled technicians offer a wide range of designs and styles to suit every taste, from elegant classics to bold statement looks. Whether you're looking for a simple manicure or intricate nail art, we have you covered. With quality products and attention to detail, our Nail Art In Andheri services ensure that your nails are not just polished, but transformed into stunning works of art. Treat yourself to a pampering session and let your nails steal the spotlight. Join us at our salon and experience the magic of Nail Art In Andheri firsthand.",
        CourseTitleOne : {
            img:"https://publish.purewow.net/wp-content/uploads/sites/2/2024/02/russian-manicure-hero.jpg?fit=1174%2C630",
            Titlename: "Nails Course",
            List:["Nail anatomy","Nail theory","Client counsaltation","Hands on buffing practice","On natural nails gel polish application practice","Acrylic nail extension’s &gel nail polish application","Acrylic inbuilt French nail extensions","Acrylic inbuilt glitter nail extensions","Gel nail extensions and gel nail polish application",
        "Gel inbuilt french nail extensions","Gel inbuilt glitter nail extensions","Refilling of the acrylic nail extensions & gel polish application","Refilling of the gel extensions &nail polish application","Removal of acrylic &gel extensions","Overlay of natural nail ","Gel paint application","Press on nails with gel paint application","Variation of nail art","Animal print","Free style","French","Marble","Blossom flowers","Ombré","Geometric lines","Accessories on nail art","Diamond fixing","Crome",
        "Cat eye","Glitter","Sticker","Stamping","Stripping tape","Foil","Velvet","Dots","Spider",'Abstract',"Geomatics","Leaf","Ink","Rose","Mylars Etc","Practice session","Certification day"
        ]
        },
        // CourseTitleTwo : {
        //     Titlename: "Basic to Advance Make-Up",
        //     List:["Self Make-up","Sider Make-up","Party Makeup","bridesmaids make-up", "college or office going makeup", "corporate Make-up"]
        // },
    },
]
export default coursesData;