import React from 'react'

const Breadcrums = ({bannerSubTitle,bannerTitle}) => {
  return (
    <>
        <section className="banner_area banner_one">
  <div className="container" bis_skin_checked={1}>
    <div className="content-align" bis_skin_checked={1}>
      <div className="content-center" bis_skin_checked={1}>
        <div className="banner_content" bis_skin_checked={1}>
          <h1 className='text-white banner-title'>{bannerTitle}</h1>
        </div>
        <div className="banner_link" bis_skin_checked={1}>
          <div className="abnner_link_inner" bis_skin_checked={1}>
            <a className="active" href="index.html">
              Home
            </a>
            <a href="contact-us.html"> {bannerSubTitle}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  )
}

export default Breadcrums