import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ServicesData from "../Asserts/ServicesData";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";

const SliderServices = () => {
  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {ServicesData.map((v,i)=>{
          return(
            <>
            <SwiperSlide style={{width:"350px"}}>
          <div className="item"  bis_skin_checked={1}>
            <div className="service-one-single" bis_skin_checked={1}>
              <div className="service-one-img-box" bis_skin_checked={1}>
                <div className="service-one-img" bis_skin_checked={1}>
                  <img
                  className="services-img"
                    src={v.servicesImg}
                    alt="Makeover  In Andheri"
                  />
                </div>
                <div className="service-title clearfix" bis_skin_checked={1}>
                  <a href="#">
                    <h2>{v.title} </h2>
                  </a>
                  
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
            </>
          )
        })}
      </Swiper>
    </>
  );
};

export default SliderServices;
