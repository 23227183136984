import img1 from "../Asserts/galleryWebp/gallery(1).webp";
import img2 from "../Asserts/galleryWebp/gallery(2).webp";
import img3 from "../Asserts/galleryWebp/gallery(3).webp";
import img4 from "../Asserts/galleryWebp/gallery(4).webp";
import img5 from "../Asserts/galleryWebp/gallery(5).webp";
import img6 from "../Asserts/galleryWebp/gallery(6).webp";
import img7 from "../Asserts/galleryWebp/gallery(7).webp";
import img8 from "../Asserts/galleryWebp/gallery(8).webp";
import img9 from "../Asserts/galleryWebp/gallery(9).webp";
import img10 from "../Asserts/galleryWebp/gallery(10).webp";
import img11 from "../Asserts/galleryWebp/gallery(11).webp";
import img12 from "../Asserts/galleryWebp/gallery(12).webp";
import img13 from "../Asserts/galleryWebp/gallery(13).webp";
import img14 from "../Asserts/galleryWebp/gallery(14).webp";

const Gallerydata = [
    { id: 1, src: img1 },
    { id: 2, src: img2 },
    { id: 3, src: img3 },
    { id: 4, src: img4 },
    { id: 5, src: img5 },
    { id: 6, src: img6 },
    { id: 7, src: img7 },
    { id: 8, src: img8 },
    { id: 9, src: img9 },
    { id: 10, src: img10 },
    { id: 11, src: img11 },
    { id: 12, src: img12 },
    { id: 13, src: img13 },
    { id: 14, src: img14 },
  ];

export default Gallerydata