import makeup from "../Asserts/imagewebp/PR(26).webp";
import nailArts from "../Asserts/webp/PR(23).webp";
const servicesData =[
    {
        title:"Make-up",
        servicesImg:makeup,
    },
    {
        title:"Nail Art",
        servicesImg:nailArts
    },
    // {
    //     title:"Manicure Men",
    //     servicesImg:"https://publish.purewow.net/wp-content/uploads/sites/2/2024/02/russian-manicure-hero.jpg?fit=1174%2C630"
    // },
    // {
    //     title:"Hair",
    //     servicesImg:"https://media.istockphoto.com/id/482679574/photo/making-a-change-of-look.jpg?s=612x612&w=0&k=20&c=Tr20-tCvPHLKh_W5pNUCymEGRUD_OfOJht97x35i8-o="
    // },
    // {
    //     title:"Makeup",
    //     servicesImg:"https://codifystudioz.com/cs-theme/parlor/demo_1/img/service/03.jpg"
    // },
    // {
    //     title:"Body Massage",
    //     servicesImg:"https://st2.depositphotos.com/1046535/8232/i/450/depositphotos_82320592-stock-photo-chinese-woman-at-wellness-massage.jpg"
    // },
    // {
    //     title:"Pedicure",
    //     servicesImg:"https://wallpapers.com/images/hd/pedicure-pictures-2qukz2q6yiw1nc82.jpg"
    // },
]
export default servicesData