import React,{useRef,useState} from "react";
import imgOne from "../Asserts/imagewebp/PR(30).webp";
import SliderServices from "../Pages/SliderServices";
import SliderTestimonial from "../Pages/SliderTestimonial";
import galery1 from "../Asserts/webp/PR(19).webp";
import galery2 from "../Asserts/webp/PR(15).webp";
import galery3 from "../Asserts/imagewebp/PR(16).webp";
import galery4 from "../Asserts/imagewebp/PR(18).webp";
import galery5 from "../Asserts/imagewebp/PR(20).webp";
import galery6 from "../Asserts/imagewebp/PR(26).webp";
import bannerLogo from "../Asserts/Logo.png";
import Certifiaction from "../Asserts/imagewebp/PR(29).webp";
import MetaTags from "react-meta-tags";
import $ from "jquery";

import {
  DownloadOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Image, Space } from "antd";
import SectionCourses from "../Pages/SectionCourses";
import Link from "antd/es/typography/Link";

const Home = () => {
    // email
    const formRef = useRef(null);
    const [custName, setcustName] = useState("");
    const [custContact, setCustContact] = useState("");
    const [custMessage, setCustMessage] = useState("");
    const [custEmail, setCustEmail] = useState("");
    const [custSubject, setCustSubject] = useState("");
    const [formError, setFormError] = useState(false);
  
    const ContactEnquiry = (e) => {
      e.preventDefault();
  
      // Validate form fields
      if (!custName  || !custMessage || !custEmail || !custSubject) {
        setFormError(true);
        return;
      }
  
      // Reset form error state
      setFormError(false);
  
      // Your form submission logic goes here
      var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Pratima Makeover Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Pratima Makeover</p></div></body></html>`;
  
      $.post(
        "https://skdm.in/server/v1/send_lead_mail.php",
        {
          toEmail: "pratimanikam17@gmail.com",
          fromEmail: "skdmlead@gmail.com",
          bccMail: "skdmlead@gmail.com",
          mailSubject: "New Customer Lead",
          mailBody: body,
          accountName: "pratimamakeover",
          accountLeadSource: "https://skdm.in",
          accountLeadName: custName,
        },
        function (dataa, status) {
          console.log("data :" + dataa);
        }
      );
  
      // Reset the form
      if (formRef.current) {
        formRef.current.reset();
      }
  
      alert("Your Form has Submitted. Our team will contact you soon.");
  
    };
  return (
    <>
      <MetaTags>
        <title>Makeover Academy In Andheri | Pratima Makeover</title>
        <meta title="Makeover Academy In Andheri | Pratima Makeover" />
        <meta
          name="description"
          content="Transform at Makeover Academy in Andheri with Pratima Makeover. Learn beauty techniques, styling, and grooming for a stunning makeover."
        />
        <meta
          name="keywords"
          content="Makeover Academy In Andheri, Best Makeover Academy In Andheri, Makeover Service In Andheri,Makeup In Andheri,Nail Art In Andheri,Makeover Courses In Andheri,Hair Dressing Course In Andheri,Beautician Course In Andheri,Bridal Makeover Course In Andheri,Saree Draping Course In Andheri,Hair Styling Course In Andheri,Top Makeover Academy Near Andheri,Makeover Academy Near Andheri"
        />
        <link rel="canonical" href="https://pratimamakeovers.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Makeover Academy In Andheri | Pratima Makeover"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://pratimamakeovers.in/" />
        <meta
          property="og:description"
          content="Transform at Makeover Academy in Andheri with Pratima Makeover. Learn beauty techniques, styling, and grooming for a stunning makeover."
        />
        <meta
          property="og:image"
          content="https://pratimamakeovers.in/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
      <div className="home-page" bis_skin_checked={1}>
        {/*================Slider Area =================*/}
        <section className="parallax-header valign bg-img bg-fixed ban-img-1">
          <div className="container" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div className="col-lg-12 text-left" bis_skin_checked={1}>
                <img src={bannerLogo} />
                <h3>hi... iam Pratima</h3>
                <h1 className="animated fadeInUp ">
                  Welcome to Makeover <br /> Academy In Andheri!
                  {/* welcome to our  beauty academy */}
                </h1>
                <br />
                <Link to='/makeover-academy-near-andheri'>
                <a href="/makeover-academy-near-andheri" className="theme-btn-white-border btn-md ">
                  book now{" "}
                </a>
                </Link>
                
              </div>
            </div>
          </div>
        </section>
        {/*================End Slider Area =================*/}
        <section className="who-iam ">
          <div className="container-fluid prl-0" bis_skin_checked={1}>
            <div className="container" bis_skin_checked={1}>
              <div className="row" bis_skin_checked={1}>
                <div className="col-xl-6 col-md-6" bis_skin_checked={1}>
                  <div
                    className="who-img-box who-iam-img"
                    bis_skin_checked={1}
                  />
                </div>
                <div className="col-xl-6 col-md-6 " bis_skin_checked={1}>
                  <div className="content-box" bis_skin_checked={1}>
                    <div className="sec-title" bis_skin_checked={1}>
                      <h1 className="white">who i am</h1>
                    </div>
                    <br />
                    <h5 className="white">
                      A renowned institution in the field of beauty,makeup, and
                      hairstyling.
                    </h5>
                    <br />
                    <p className="white">
                      Welcome to Makeover Academy In Andheri, where we cultivate
                      talent and passion for beauty. Our academy offers
                      comprehensive courses, including beauty and bridal
                      programs, led by experienced professionals. With a focus
                      on hands-on training and personalized support, we empower
                      students to excel in the industry. Whether you're a
                      beginner or seasoned professional, our curriculum caters
                      to all skill levels. At our academy, we prioritize
                      creativity, innovation, and individual growth, providing
                      the tools and guidance needed to succeed. Join us at
                      Makeover Academy and embark on a transformative journey
                      toward realizing your dreams in the dynamic world of
                      beauty.
                    </p>
                    <br />
                    {/* <a href="#" className="theme-btn-white-border btn-md">
                      read more
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="why-choose section-padding pb-0">
          <div className="container my-5" bis_skin_checked={1}>
            <div className="title" bis_skin_checked={1}>
              <div className="row" bis_skin_checked={1}>
                <div className="col-xl-6 col-md-6" bis_skin_checked={1}>
                  <div className="sec-title text-left" bis_skin_checked={1}>
                    <h1>what we do</h1>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6" bis_skin_checked={1}>
                  <div className="title-text text-left" bis_skin_checked={1}>
                    <p>
                      Stylish hair cuts, gorgeous styling, incredible colour
                      techniques, best hair servces, and pampering beauty
                      treatment.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div
                className="col-xl-6 col-lg-6 col-md-12 col-sm-12 prl-0 mb-3"
                bis_skin_checked={1}
              >
                <div className=" d-lg-block d-md-none" bis_skin_checked={1}>
                  <img
                    src={imgOne}
                    alt="Makeover Academy"
                    style={{ width: "100%", objectFit: "cover" }}
                  />
                </div>
              </div>
              <div className="col-xl-6 col-md-12 " bis_skin_checked={1}>
                <div className="row" bis_skin_checked={1}>
                  <div
                    className="col-xl-6 col-md-6 col-sm-6 pr-0 mb-3"
                    bis_skin_checked={1}
                  >
                    <div className="fea-box bg-col-yellow" bis_skin_checked={1}>
                      <div className="img-box" bis_skin_checked={1}>
                        <img
                        className="what-we-do-grid"
                          src="https://t4.ftcdn.net/jpg/02/44/16/37/360_F_244163733_ErNyvrHfOJcRlHd7t3doQcs4bEgclAfq.jpg"
                          alt=" Academy In Andheri"
                        />
                      </div>
                      <div className="content-box" bis_skin_checked={1}>
                        <h4>High quality training</h4>
                        <br />
                        <p>
                          Unlock your potential with our high-quality beauty
                           training, where expertise meets innovation for
                          your success.
                        </p>
                        <br />
                        <div className="btm-border" bis_skin_checked={1} />
                        <br />
                        {/* <a href="#" className="r-more">
                          read more
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/* /col */}
                  <div
                    className="col-xl-6 col-md-6 col-sm-6 pl-0 mb-3"
                    bis_skin_checked={1}
                  >
                    <div className="fea-box " bis_skin_checked={1}>
                      <div className="img-box" bis_skin_checked={1}>
                        <img
                        className="what-we-do-grid"
                          src="https://www.ziprecruiter.com/svc/fotomat/public-ziprecruiter/cms/687244776MakeupArtist.jpg=ws1280x960"
                          alt="img"
                        />
                      </div>
                      <div className="content-box" bis_skin_checked={1}>
                        <h4 className="white">Professional Development</h4>
                        <br />
                        <p className="white">
                          Elevate your skills and career with our professional
                          development programs tailored for success in the
                          beauty  industry.
                        </p>
                        <br />
                        <div className="btm-border" bis_skin_checked={1} />
                        <br />
                        {/* <a href="#" className="r-more white">
                          read more
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/* /col */}
                  <div
                    className="col-xl-6 col-md-6 col-sm-6 pr-0 mb-3"
                    bis_skin_checked={1}
                  >
                    <div className="fea-box" bis_skin_checked={1}>
                      <div className="img-box" bis_skin_checked={1}>
                        <img src={Certifiaction} className="what-we-do-grid" alt="img" />
                      </div>
                      <div className="content-box" bis_skin_checked={1}>
                        <h4 className="white">
                        Certification &amp; Recognition
                        </h4>
                        <br />
                        <p className="white">
                          Gain industry-leading certification and recognition at
                          our Academy, where excellence is celebrated and
                          expertise is honored.
                        </p>
                        <br />
                        <div className="btm-border" bis_skin_checked={1} />
                        <br />
                        {/* <a href="#" className="r-more white">
                          read more
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/* /col */}
                  <div
                    className="col-xl-6 col-md-6 col-sm-6 pl-0 mb-3"
                    bis_skin_checked={1}
                  >
                    <div className="fea-box bg-col-yellow" bis_skin_checked={1}>
                      <div className="img-box" bis_skin_checked={1}>
                        <img
                        className="what-we-do-grid"
                          src="https://us.123rf.com/450wm/morganka/morganka1901/morganka190100063/117163750-close-up-view-of-female-face-with-bright-multicolored-fashion-makeup-holi-indian-color-festival.jpg?ver=6"
                          alt="img"
                        />
                      </div>
                      <div className="content-box" bis_skin_checked={1}>
                        <h4>Innovation &amp; Creativity</h4>
                        <br />
                        <p>
                          Experience the forefront of innovation and creativity
                          at our Academy, where every visit is a masterpiece in
                          the making.
                        </p>
                        <br />
                        <div className="btm-border" bis_skin_checked={1} />
                        <br />
                        {/* <a href="#" className="r-more">
                          read more
                        </a> */}
                      </div>
                    </div>
                  </div>
                  {/* /col */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* courses section start */}
        <SectionCourses />
        {/* courses section end */}
        <section className="service2 section-padding pb-0 ">
          <div className="container my-5" bis_skin_checked={1}>
            <div className="title" bis_skin_checked={1}>
              <div className="row" bis_skin_checked={1}>
                <div className="col-xl-6 col-md-6" bis_skin_checked={1}>
                  <div className="sec-title text-left" bis_skin_checked={1}>
                    <h1 className="text-black">our services</h1>
                  </div>
                </div>
                <div className="col-xl-6 col-md-6" bis_skin_checked={1}>
                  <div className="title-text text-left" bis_skin_checked={1}>
                    <p>
                      From precision cuts to rejuvenating treatments, our
                      skilled professionals deliver unparalleled results that
                      exceed expectations. Step into our oasis of pampering and
                      relaxation, where every service is designed to enhance
                      your natural radiance. Embrace a new level of
                      sophistication and confidence with our signature Makeover
                      Academy in Andheri offerings.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /container */}
          <div className="bg-black" bis_skin_checked={1}>
            <div className="container" bis_skin_checked={1}>
              <div className="row" bis_skin_checked={1}>
                <div className="" bis_skin_checked={1}>
                  <SliderServices />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* gallery start  */}
        <section className="gallery section-padding pb-0">
          <div className="container my-5" bis_skin_checked={1}>
            <div className="title" bis_skin_checked={1}>
              <div className="row" bis_skin_checked={1}>
                <div className="col-xl-6" bis_skin_checked={1}>
                  <div className="sec-title text-left" bis_skin_checked={1}>
                    <h1>photo gallery</h1>
                  </div>
                </div>
                <div className="col-xl-6" bis_skin_checked={1}>
                  <div className="title-text text-left" bis_skin_checked={1}>
                    <p>
                      Explore our captivating photo gallery showcasing stunning
                      transformations and breathtaking beauty moments, inspiring
                      your next Academy visit. Witness the artistry and expertise
                      of our talented team, capturing the essence of elegance
                      and sophistication in every image.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /container */}
          <div className="" bis_skin_checked={1}>
            <div className="container" bis_skin_checked={1}>
              <div className="row" bis_skin_checked={1}>
                <div className="col-lg-4 mb-3" bis_skin_checked={1}>
                  <Image
                    //   margin=
                    //   width={450}
                    src={galery1}
                    preview={{
                      toolbarRender: (
                        _,
                        {
                          transform: { scale },
                          actions: {
                            onFlipY,
                            onFlipX,
                            onRotateLeft,
                            onRotateRight,
                            onZoomOut,
                            onZoomIn,
                          },
                        }
                      ) => (
                        <Space size={12} className="toolbar-wrapper">
                          <SwapOutlined rotate={90} onClick={onFlipY} />
                          <SwapOutlined onClick={onFlipX} />
                          <RotateLeftOutlined onClick={onRotateLeft} />
                          <RotateRightOutlined onClick={onRotateRight} />
                          <ZoomOutOutlined
                            disabled={scale === 1}
                            onClick={onZoomOut}
                          />
                          <ZoomInOutlined
                            disabled={scale === 50}
                            onClick={onZoomIn}
                          />
                        </Space>
                      ),
                    }}
                  />
                </div>
                <div className="col-lg-4 mb-3" bis_skin_checked={1}>
                  <Image
                    //   width={450}
                    src={galery2}
                    preview={{
                      toolbarRender: (
                        _,
                        {
                          transform: { scale },
                          actions: {
                            onFlipY,
                            onFlipX,
                            onRotateLeft,
                            onRotateRight,
                            onZoomOut,
                            onZoomIn,
                          },
                        }
                      ) => (
                        <Space size={12} className="toolbar-wrapper">
                          <SwapOutlined rotate={90} onClick={onFlipY} />
                          <SwapOutlined onClick={onFlipX} />
                          <RotateLeftOutlined onClick={onRotateLeft} />
                          <RotateRightOutlined onClick={onRotateRight} />
                          <ZoomOutOutlined
                            disabled={scale === 1}
                            onClick={onZoomOut}
                          />
                          <ZoomInOutlined
                            disabled={scale === 50}
                            onClick={onZoomIn}
                          />
                        </Space>
                      ),
                    }}
                  />
                </div>
                <div className="col-lg-4 mb-3" bis_skin_checked={1}>
                  <Image
                    //   width={450}
                    src={galery3}
                    preview={{
                      toolbarRender: (
                        _,
                        {
                          transform: { scale },
                          actions: {
                            onFlipY,
                            onFlipX,
                            onRotateLeft,
                            onRotateRight,
                            onZoomOut,
                            onZoomIn,
                          },
                        }
                      ) => (
                        <Space size={12} className="toolbar-wrapper">
                          <SwapOutlined rotate={90} onClick={onFlipY} />
                          <SwapOutlined onClick={onFlipX} />
                          <RotateLeftOutlined onClick={onRotateLeft} />
                          <RotateRightOutlined onClick={onRotateRight} />
                          <ZoomOutOutlined
                            disabled={scale === 1}
                            onClick={onZoomOut}
                          />
                          <ZoomInOutlined
                            disabled={scale === 50}
                            onClick={onZoomIn}
                          />
                        </Space>
                      ),
                    }}
                  />
                </div>
                <div className="col-lg-4 mb-3" bis_skin_checked={1}>
                  <Image
                    //   width={450}
                    src={galery4}
                    preview={{
                      toolbarRender: (
                        _,
                        {
                          transform: { scale },
                          actions: {
                            onFlipY,
                            onFlipX,
                            onRotateLeft,
                            onRotateRight,
                            onZoomOut,
                            onZoomIn,
                          },
                        }
                      ) => (
                        <Space size={12} className="toolbar-wrapper">
                          <SwapOutlined rotate={90} onClick={onFlipY} />
                          <SwapOutlined onClick={onFlipX} />
                          <RotateLeftOutlined onClick={onRotateLeft} />
                          <RotateRightOutlined onClick={onRotateRight} />
                          <ZoomOutOutlined
                            disabled={scale === 1}
                            onClick={onZoomOut}
                          />
                          <ZoomInOutlined
                            disabled={scale === 50}
                            onClick={onZoomIn}
                          />
                        </Space>
                      ),
                    }}
                  />
                </div>
                <div className="col-lg-4 mb-3" bis_skin_checked={1}>
                  <Image
                    //   width={450}
                    src={galery5}
                    preview={{
                      toolbarRender: (
                        _,
                        {
                          transform: { scale },
                          actions: {
                            onFlipY,
                            onFlipX,
                            onRotateLeft,
                            onRotateRight,
                            onZoomOut,
                            onZoomIn,
                          },
                        }
                      ) => (
                        <Space size={12} className="toolbar-wrapper">
                          <SwapOutlined rotate={90} onClick={onFlipY} />
                          <SwapOutlined onClick={onFlipX} />
                          <RotateLeftOutlined onClick={onRotateLeft} />
                          <RotateRightOutlined onClick={onRotateRight} />
                          <ZoomOutOutlined
                            disabled={scale === 1}
                            onClick={onZoomOut}
                          />
                          <ZoomInOutlined
                            disabled={scale === 50}
                            onClick={onZoomIn}
                          />
                        </Space>
                      ),
                    }}
                  />
                </div>
                <div className="col-lg-4 mb-3" bis_skin_checked={1}>
                  <Image
                    //   width={450}
                    src={galery6}
                    preview={{
                      toolbarRender: (
                        _,
                        {
                          transform: { scale },
                          actions: {
                            onFlipY,
                            onFlipX,
                            onRotateLeft,
                            onRotateRight,
                            onZoomOut,
                            onZoomIn,
                          },
                        }
                      ) => (
                        <Space size={12} className="toolbar-wrapper">
                          <SwapOutlined rotate={90} onClick={onFlipY} />
                          <SwapOutlined onClick={onFlipX} />
                          <RotateLeftOutlined onClick={onRotateLeft} />
                          <RotateRightOutlined onClick={onRotateRight} />
                          <ZoomOutOutlined
                            disabled={scale === 1}
                            onClick={onZoomOut}
                          />
                          <ZoomInOutlined
                            disabled={scale === 50}
                            onClick={onZoomIn}
                          />
                        </Space>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* gallery end  */}

        <section className="testimonial-area section-padding">
          <div className="container my-5" bis_skin_checked={1}>
            <div className="title" bis_skin_checked={1}>
              <div className="row" bis_skin_checked={1}>
                <div className="col-xl-6" bis_skin_checked={1}>
                  <div className="sec-title text-left" bis_skin_checked={1}>
                    <h1>testimonials</h1>
                  </div>
                </div>
                <div className="col-xl-6" bis_skin_checked={1}>
                  <div className="title-text text-left" bis_skin_checked={1}>
                    <p>
                      Discover the glowing testimonials from our satisfied
                      clients, sharing their remarkable experiences and rave
                      reviews of our exceptional courses. Join the chorus of
                      delighted customers who have entrusted us to bring their
                      beauty visions to life with expertise and care.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div className="" bis_skin_checked={1}>
                <div className="main-frame" bis_skin_checked={1}>
                  <SliderTestimonial />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* get in touch start */}
        <section className="make-appoitment bg-img bg-img-4 section-padding ">
          <div className="container" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div className="col-xl-5 col-md-7" bis_skin_checked={1}>
                <form className="appoint_form my-4">
                  <div className="title" bis_skin_checked={1}>
                    <h3>MAKE AN APPOITMENT</h3>
                    <p>
                      Welcome to our service, where we cater to your needs and
                      exceed expectations. Join us today for an unforgettable
                      experience.
                    </p>
                  </div>
                  <div className="row" bis_skin_checked={1}>
                    <div className="form-group col-md-12" bis_skin_checked={1}>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Name"
                        onChange={(e) => setcustName(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-12" bis_skin_checked={1}>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Email"
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-12" bis_skin_checked={1}>
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        placeholder="Subject"
                        onChange={(e) => setCustSubject(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-12" bis_skin_checked={1}>
                      <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        rows={1}
                        placeholder="Message.."
                        defaultValue={""}
                        onChange={(e) => setCustMessage(e.target.value)}

                      />
                    </div>
                    {formError && (
          <p style={{ color: "red" }}>Please fill in all required fields</p>
        )}
                    <div className="form-group col-md-12" bis_skin_checked={1}>
                      <button
                        type="submit"
                        value="submit"
                        className="theme-btn-2 btn-full mt-none"
                        onClick={ContactEnquiry}
                      >
                        Submit now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      

       
        
      </div>
    </>
  );
};

export default Home;
