import React, { useLayoutEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import ServicesData from "../Asserts/ServicesData";
// import required modules
import { Pagination } from "swiper/modules";
import Breadcrums from "./Breadcrums";
import MetaTags from 'react-meta-tags';

const Services = () => {
  useLayoutEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <>
    <MetaTags>
      <title>Makeover Service In Andheri | Pratima Makeover</title>
        <meta title="Makeover Service In Andheri | Pratima Makeover" />
        <meta
          name="description"
          content="Transform your look with the best makeover service in Andheri. Experience Pratima Makeover's expertise for a stunning transformation."
        />
        <meta
          name="keywords"
          content="Makeover Academy In Andheri, Best Makeover Academy In Andheri, Makeover Service In Andheri,Makeup In Andheri,Nail Art In Andheri,Makeover Courses In Andheri,Hair Dressing Course In Andheri,Beautician Course In Andheri,Bridal Makeover Course In Andheri,Saree Draping Course In Andheri,Hair Styling Course In Andheri,Top Makeover Academy Near Andheri,Makeover Academy Near Andheri"
        />
        <link rel="canonical" href="https://pratimamakeovers.in/makeover-service-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Makeover Service In Andheri | Pratima Makeover" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://pratimamakeovers.in/makeover-service-andheri"/>
        <meta property="og:description" content="Transform your look with the best makeover service in Andheri. Experience Pratima Makeover's expertise for a stunning transformation." />
        <meta property="og:image" content="https://pratimamakeovers.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      <p className="d-none">
      Welcome to our Pratima Makeover, where beauty meets expertise! At our academy, we offer an extensive range of courses designed to unlock your potential and ignite your passion for the beauty industry. From foundational beauty courses to specialized bridal courses, we provide comprehensive training led by industry experts to ensure you acquire the skills and knowledge needed to excel in your craft.

Our academy is more than just a place of learning; it's a nurturing environment where creativity thrives, and dreams are transformed into reality. Whether you're a novice seeking to kickstart your career or a seasoned professional looking to refine your skills, our diverse course offerings cater to individuals at every skill level.

But our commitment to beauty doesn't end there. At our salon, we offer a wide range of premium beauty services tailored to meet the unique needs of our clientele. From flawless makeup applications to intricate hairstyles, our team of experienced professionals is dedicated to providing personalized experiences that leave you feeling confident and beautiful.

What sets us apart is our dedication to convenience and accessibility. For our nearby customers who may not be able to visit our salon, we offer exclusive bridal home services. Whether it's your wedding day or a special occasion, our team will bring the salon experience to you, ensuring you receive the same level of exceptional service and attention to detail in the comfort of your own space.

Located conveniently for our nearby customers, we strive to be more than just a salon and academy – we're your trusted partner in achieving your beauty goals. Join us at our Makeover Academy and Salon, where we're committed to helping you unleash your inner beauty and confidence, one transformation at a time.

      </p>
    <Breadcrums bannerTitle="Makeover Service In Andheri" bannerSubTitle="Services" />
    <section>
        <div className="container"> 
            <div className="row">
            {ServicesData.map((v,i)=>{
            return(
                <>
                {/* <SwiperSlide style={{width:"350px"}}> */}
          <div className="col-lg-4 my-4">
          <div className="item shadow" bis_skin_checked={1}>
            <div className="service-one-single" bis_skin_checked={1}>
              <div className="service-one-img-box" bis_skin_checked={1}>
                <div className="service-one-img" bis_skin_checked={1}>
                  <img
                  className="services-img"
                  style={{width:"100%"}}
                    src={v.servicesImg}
                    alt="img"
                  />
                </div>
                <div className="service-title clearfix" bis_skin_checked={1}>
                  <a href="#">
                    <h2>{v.title} </h2>
                  </a>
                  {/* <a href="#" className="arrow">
                    <i className="fas fa-arrow-right" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          </div>
        {/* </SwiperSlide> */}
                </>
            )
        })}

            </div>

        </div>
    </section>
    </>
  );
};

export default Services;
