import React from 'react'
import '../Css/Footer.css'
import CoursesData from "../Asserts/CoursesData";
import { Link } from 'react-router-dom';
import Logo from "../Asserts/Logo.png";
const Footer = () => {
  return (
    <>
         {/*================Footer Area =================*/}
  <footer className="footer-section">
    <div className="footer_area" bis_skin_checked={1}>
      <div className="container" bis_skin_checked={1}>
        <div className="row" bis_skin_checked={1}>
          <div
            className="col-lg-4 col-md-12 col-sm-12 col-xs-12"
            bis_skin_checked={1}
          >
            <div className="footer-about" bis_skin_checked={1}>
              <div className="footer-logo" bis_skin_checked={1}>
                <img src={Logo} alt="logo" />
              </div>
              <p>
              we cultivate
                      talent and passion for beauty. Our academy offers
                      comprehensive courses, including beauty and bridal
                      programs, led by experienced professionals. With a focus
                      on hands-on training and personalized support, we empower
                      students to excel in the industry.
              </p>
              <div className="social-links-footer" bis_skin_checked={1}>
                <a href="https://www.facebook.com/pratimamakeoveracademy/" target='_blank'>
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="https://www.instagram.com/pratimamakeoveracademy/" target='_blank'>
                  <i className="fab fa-instagram" />
                </a>
                {/* <a href="#">
                  <i className="fab fa-google-plus-g" />
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in" />
                </a> */}
              </div>
            </div>
          </div>
          <div
            className="col-lg-2 col-md-3 col-sm-3 col-xs-5"
            bis_skin_checked={1}
          >
            <aside className="footer-widget pl-45">
              <div className="f_title" bis_skin_checked={1}>
                <h3>Quick Links</h3>
              </div>
              <div className="footer-link" bis_skin_checked={1}>
                <ul>
                  <li>
                    <Link to='/'>
                    <a href="/">Home</a>
                    </Link>
                  </li>
                  <li>
                    <Link to='/best-makeover-academy-andheri' ><a href="/best-makeover-academy-andheri">About us</a></Link>
                  </li>
                  {/* <li>
                    <a href="#">Clients</a>
                  </li>
                  <li>
                    <a href="#">FAQ</a>
                  </li> */}
                  <li>
                  <Link to='/makeover-service-andheri'><a href="/makeover-service-andheri">Services</a></Link>
                    
                  </li>
                </ul>
              </div>
            </aside>
          </div>
          <div className="col-lg-2 col-md-3" bis_skin_checked={1}>
            <div className="footer-widget pl-55" bis_skin_checked={1}>
              <div className="f_title" bis_skin_checked={1}>
                <h3>Courses</h3>
              </div>
              <div className="footer-link" bis_skin_checked={1}>
                <ul>
                {CoursesData.map( v=> <li> <Link to={v.slugs}> <a href="#">{v.courseName} </a></Link>   </li>)}
                </ul>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-6 col-sm-5 col-xs-12 "
            bis_skin_checked={1}
          >
            <aside className="footer-widget pl-75 mb-none">
              <div className="f_title" bis_skin_checked={1}>
                <h3>Address</h3>
              </div>
              <div className="footer-address" bis_skin_checked={1}>
                <ul>
                  <li>
                    <div className="media-left" bis_skin_checked={1}>
                      <i className="fa fa-map-marker-alt" aria-hidden="true" />
                    </div>
                    <div className="media-body d-flex" bis_skin_checked={1}>
                      <p className="color-white">
                      3/19 Mahadev Bhavan, New Nagardas Road, Near Pinky Cinema, Andheri East, Mumbai, India, Maharashtra
                      </p>
                    </div>
                  </li>
                  <li>
                    <a href="tel:88796 76606">
                      <div className="media-left" bis_skin_checked={1}>
                        <i className="fa fa-phone-alt" aria-hidden="true" />
                      </div>
                      <div className="media-body" bis_skin_checked={1}>
                        +91-88796 76606
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="tel:88796 76623">
                      <div className="media-left" bis_skin_checked={1}>
                        <i className="fa fa-phone-alt" aria-hidden="true" />
                      </div>
                      <div className="media-body" bis_skin_checked={1}>
                        +91-88796 76623
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:Pratimanikam17@gmail.com">
                      {" "}
                      <div className="media-left" bis_skin_checked={1}>
                        <i className="fa fa-envelope" aria-hidden="true" />
                      </div>
                      <div className="media-body" bis_skin_checked={1}>
                      Pratimanikam17@gmail.com
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
    </div>
    <div className="footer_copyright" bis_skin_checked={1}>
      <div className="container" bis_skin_checked={1}>
        <div className="footer_copyright_inner" bis_skin_checked={1}>
          <p>© 2024 All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </footer>
    </>
  )
}

export default Footer