import React, {useLayoutEffect } from "react";
import MetaTags from "react-meta-tags";

import Breadcrums from "../Pages/Breadcrums";
import pro1 from "../Asserts/pro1.webp";
import pro2 from "../Asserts/pro2.webp";
import pro3 from "../Asserts/pro3.webp";
import mission from "../Asserts/mission.webp";

const AboutUs = () => {
  useLayoutEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <>
      <MetaTags>
        <title>Best Makeover Academy In Andheri | Pratima Makeover</title>
        <meta title="Best Makeover Academy In Andheri | Pratima Makeover" />
        <meta
          name="description"
          content="Transform yourself at Pratima Makeover, renowned as the Best Makeover Academy in Andheri, offering top-notch beauty training and services."
        />
        <meta
          name="keywords"
          content="Makeover Academy In Andheri, Best Makeover Academy In Andheri, Makeover Service In Andheri,Makeup In Andheri,Nail Art In Andheri,Makeover Courses In Andheri,Hair Dressing Course In Andheri,Beautician Course In Andheri,Bridal Makeover Course In Andheri,Saree Draping Course In Andheri,Hair Styling Course In Andheri,Top Makeover Academy Near Andheri,Makeover Academy Near Andheri"
        />
        <link rel="canonical" href="https://pratimamakeovers.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Best Makeover Academy In Andheri | Pratima Makeover"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://pratimamakeovers.in/best -makeover-academy-andheri"
        />
        <meta
          property="og:description"
          content="Transform yourself at Pratima Makeover, renowned as the Best Makeover Academy in Andheri, offering top-notch beauty training and services."
        />
        <meta
          property="og:image"
          content="https://pratimamakeovers.in/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
      <Breadcrums bannerTitle="Best Makeover Academy In Andheri" bannerSubTitle=" About Us" />
      <section className="com-about ">
        <div className="container" bis_skin_checked={1}>
          <div className="row" bis_skin_checked={1}>
            <div className="col-lg-6">
              <div className="image_thumb" bis_skin_checked={1}>
                <img
                  src="https://www.shutterstock.com/image-photo/young-woman-visit-beauty-salon-600nw-1551548927.jpg"
                  loading="lazy"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 " bis_skin_checked={1}>
              <div className="company-content-area" bis_skin_checked={1}>
                <h6>ABOUT US</h6>
                <h1>ABOUT MY ACADEMY</h1>
                <p>
                  Welcome to our Pratima makeover, the Best Makeover Academy In
                  Andheri, where excellence meets innovation and every visit
                  promises a transformative experience. With a steadfast
                  commitment to providing top-tier services and comprehensive
                  training, we are dedicated to nurturing talent and elevating
                  standards within the beauty industry.
                </p>
                <p className="d-none">
                  In addition to our comprehensive courses, we take pride in
                  providing exquisite bridal services tailored exclusively for
                  our nearby customers. But what sets us apart is our commitment
                  to convenience. For those unable to visit our Academy, we
                  offer bespoke bridal home services, ensuring that every bride
                  receives the attention and care she deserves in the comfort of
                  her own space. Located conveniently for our nearby customers,
                  we strive to be more than just a beauty destination; we're
                  your trusted partner in achieving your dream look. Join us at
                  our Academy and let us accompany you on your journey to beauty
                  and self-discovery. Complementing our exceptional Academy
                  services is our state-of-the-art training center, where
                  aspiring beauty professionals embark on a journey of personal
                  and professional growth. Our comprehensive courses cover a
                  wide range of topics, from foundational techniques to advanced
                  trends, ensuring that students graduate with the skills and
                  knowledge needed to thrive in the competitive beauty industry.
                  Led by industry experts with years of experience, our hands-on
                  approach to education allows students to hone their craft and
                  develop their own unique style under the guidance of seasoned
                  professionals. Upon completion of our training programs,
                  students receive industry-leading certification and
                  recognition, empowering them to pursue rewarding careers in
                  the beauty industry with confidence and credibility. Whether
                  launching a solo career or seeking employment at top Academies
                  and spas worldwide, our graduates are equipped with the
                  expertise and credentials to succeed in any professional
                  setting.
                </p>
                <br />
                <p>
                  Join us at our Best Makeover Academy In Andheri and experience
                  the pinnacle of beauty excellence. From luxurious Academy
                  services to world-class training programs, we are dedicated to
                  helping you unleash your full potential and achieve your
                  dreams in the dynamic and ever-evolving world of beauty.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="core-value section-padding pb-0">
        <div className="container" bis_skin_checked={1}>
          <div className="title" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div className="col-xl-6 col-lg-6 col-md-6 " bis_skin_checked={1}>
                <div className="sec-title text-left" bis_skin_checked={1}>
                  <h1>core value</h1>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6" bis_skin_checked={1}>
                <div className="title-text text-left" bis_skin_checked={1}>
                  <p>
                    At the Best Makeover Academy In Andheri, core values revolve
                    around excellence and integrity, ensuring that every client
                    receives the highest quality service with honesty and
                    professionalism at the forefront of everything we do.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="core-wrap" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div
                className="col-xl-3 col-lg-3 col-md-4 col-xs-12 prl-0"
                bis_skin_checked={1}
              >
                <div className="counter-area" bis_skin_checked={1}>
                  <div className="achivement" bis_skin_checked={1}>
                    <h1>
                      <span className="counter">20</span>
                    </h1>
                    <h4>Years of experience</h4>
                  </div>
                </div>
              </div>
              {/* /col */}
              <div
                className="col-xl-3 col-lg-3 col-md-4 col-xs-12 pr-0"
                bis_skin_checked={1}
              >
                <div className="counter-area" bis_skin_checked={1}>
                  <div className="achivement pr-37" bis_skin_checked={1}>
                    <h1>
                      <span className="counter">1500 </span>
                      <div className="plus-size" bis_skin_checked={1}>
                        +
                      </div>
                    </h1>
                    <h4>Happy Customer</h4>
                  </div>
                </div>
              </div>
              {/* /col */}
              <div
                className="col-xl-3 col-lg-3 col-md-4 col-xs-12 prl-0"
                bis_skin_checked={1}
              >
                <div className="counter-area" bis_skin_checked={1}>
                  <div className="achivement brd-r-0" bis_skin_checked={1}>
                    <h1>
                      <span className="counter">1</span>
                    </h1>
                    <h4>branches</h4>
                  </div>
                </div>
              </div>
              {/* /col */}
            </div>
            {/* /row */}
            <div className="row" bis_skin_checked={1}>
              <div
                className="col-xl-3 col-lg-3 col-md-4 col-xs-12 pr-0"
                bis_skin_checked={1}
              >
                <div className="pro-box bg-col-black" bis_skin_checked={1}>
                  <div className="icon" bis_skin_checked={1}>
                    <img src={pro1} loading="lazy" alt="" />
                  </div>
                  {/* <br> */}
                  <h1>Top professional Artist</h1>
                </div>
              </div>
              {/* /col */}
              <div
                className="col-xl-3 col-lg-3 col-md-4 col-xs-12 prl-0"
                bis_skin_checked={1}
              >
                <div className="pro-box bg-col-pink" bis_skin_checked={1}>
                  <div className="icon" bis_skin_checked={1}>
                    <img src={pro2} loading="lazy" alt="" />
                  </div>
                  {/* <br> */}
                  <h1>Free Consultations</h1>
                </div>
              </div>
              {/* /col */}
              <div
                className="col-xl-3 col-lg-3 col-md-4 col-xs-12 pl-0"
                bis_skin_checked={1}
              >
                <div className="pro-box bg-col-skin" bis_skin_checked={1}>
                  <div className="icon" bis_skin_checked={1}>
                    <img src={pro3} loading="lazy" alt="" />
                  </div>
                  {/* <br> */}
                  <h1>Creative &amp; Smart Work</h1>
                </div>
              </div>
              {/* /col */}
            </div>
          </div>
        </div>
      </section>
      <section className="our-mission ">
        <div className="container pt-4" bis_skin_checked={1}>
          <div
            className="image-box bg-img-mission clearfix"
            bis_skin_checked={1}
          >
            <img src={mission} loading="lazy" alt="" />
          </div>
          <div className="container" bis_skin_checked={1}>
            <div className="row" bis_skin_checked={1}>
              <div className="col-xl-12 col-xs-12" bis_skin_checked={1}>
                <div className="cnt-box pb-xs-0" bis_skin_checked={1}>
                  <h1>Our Mission</h1>
                  <br />
                  <p>
                    Our mission at the Best Makeover Academy In Andheri is to
                    inspire and empower individuals to unlock their full
                    potential in the beauty industry. Through comprehensive
                    courses and personalized guidance, we aim to cultivate
                    creativity, skill, and confidence in every student. 
                  </p>
                  <br />
                  <p>
                  We are committed to providing top-notch education, equipping
                    aspiring beauty professionals with the knowledge and
                    expertise needed to thrive in a competitive market. Our
                    dedication to excellence extends beyond the classroom, as we
                    strive to foster a supportive community that celebrates
                    diversity and innovation. At the Makeover Academy, we
                    believe in transforming dreams into reality, one makeover at
                    a time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
