import React, { useState } from "react";
import "../Css/Header.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
// import Logo from "../Images/5 (1).png";
import CoursesData from "../Asserts/CoursesData";
import Logo from "../Asserts/jpeg-optimizer_Untitled-1.png";
const NavBar = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 78) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);
  return (
    <div>
      <section className="NavSection">
        <div className="container-fluid">
          <Navbar
            collapseOnSelect
            expand="lg"
            variant="dark"
            className={color ? "NavBar NavBar-bg" : "NavBar"}
          >
            <Container>
              <Navbar.Brand href="/">
                <img
                  src={Logo}
                  alt=""
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav" className="main-nav">
                <Nav>
                  <Nav.Link href="/" className="nav-link">
                    {" "}
                    <Link to="/" className="nav-link">
                      {" "}
                      Home
                    </Link>
                  </Nav.Link>

                  <Nav.Link className="nav-link" href="/best-makeover-academy-andheri">
                    {" "}
                    <Link className="nav-link" to="/best-makeover-academy-andheri">
                      About Us
                    </Link>
                  </Nav.Link>
                  <NavDropdown className="nav-link"  title="Course" id="basic-nav-dropdown">
              {/* <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item> */}
              {CoursesData.map((ele) => {
                                    const { courseName ,slugs } = ele;
                                    return (
                                    <>
                                    <Nav.Link href="/" className="text-black"><Link className="text-black" to={`${slugs}`}>{courseName}</Link></Nav.Link>
                                    </>
                                    );
                                })}
            </NavDropdown>
                  <Nav.Link
                    className="nav-link"
                    href="/makeover-service-andheri"
                  >
                    {" "}
                    <Link
                      className="nav-link"
                      to="/makeover-service-andheri"
                    >
                      Services
                    </Link>
                  </Nav.Link>
                

                  <Nav.Link href="/top-makeover-academy-near-andheri" className="nav-link">
                    {" "}
                    <Link to="/top-makeover-academy-near-andheri" className="nav-link">
                      {" "}
                      Gallery
                    </Link>
                  </Nav.Link>
                  <Nav.Link className="nav-link" href="/makeover-academy-near-andheri">
                    {" "}
                    <Link className="nav-link" to="/makeover-academy-near-andheri">
                      Contact Us
                    </Link>
                  </Nav.Link>
                  
                </Nav>
              </Navbar.Collapse>

              <div className="socialLinks d-lg-flex d-none">
                <a
                  href="/makeover-academy-near-andheri"
                  // target="_blank"
                >
                  {" "}
                  <i className="fa fa-address-book" /> <span className="text-white">Enquiry Now</span>
                </a>
              </div>
            </Container>
          </Navbar>
        </div>
      </section>
    </div>
  );
};

export default NavBar;
