import React,{useLayoutEffect } from "react";
import Breadcrums from "./Breadcrums";
import MetaTags from 'react-meta-tags';

import "../Css/Courses.css";
import { useLocation } from "react-router-dom";
import CoursesData from "../Asserts/CoursesData";
const Courses = () => {
  useLayoutEffect(()=>{
    window.scrollTo(0,0)
  })
  const search = useLocation();
  const path = search.pathname;
  console.log(path);
  const getData = CoursesData.find((v) => v.slugs === path);
  console.log(getData);
  const canonical = `${window.location.origin}${path}`
  // console.log(canonical);
  return (
    <>
    <MetaTags>
      <title>{getData.title}</title>
        <meta title={getData.title} />
        <meta
          name="description"
          content={getData.seoDesc}
        />
        <meta
          name="keywords"
          content="Makeover Academy In Andheri, Best Makeover Academy In Andheri, Makeover Service In Andheri,Makeup In Andheri,Nail Art In Andheri,Makeover Courses In Andheri,Hair Dressing Course In Andheri,Beautician Course In Andheri,Bridal Makeover Course In Andheri,Saree Draping Course In Andheri,Hair Styling Course In Andheri,Top Makeover Academy Near Andheri,Makeover Academy Near Andheri"
        />
        <link rel="canonical" href={canonical} />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content={getData.title} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={canonical}/>
        <meta property="og:description" content={getData.seoDesc} />
        <meta property="og:image" content="https://pratimamakeovers.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      <Breadcrums bannerTitle={getData.h1} bannerSubTitle={getData.courseName} />
     <section>
     <div className="service_page" bis_skin_checked={1}>
        <div className="container" bis_skin_checked={1}>
          <div className="row" bis_skin_checked={1}>
            <div className="col-md-10 " bis_skin_checked={1}>
              <div className="service_content_right_col" bis_skin_checked={1}>
                <div className="tab-content" bis_skin_checked={1}>
                <h2 className="course-title-sm d-none">{getData.CourseTitleOne.Titlename} </h2>

                  <div className="service_content_bx" bis_skin_checked={1}>

                    <div className="row" bis_skin_checked={1}>
                      <div className="col-md-6 " bis_skin_checked={1}>
                        <div className="image_thumb" bis_skin_checked={1}>
                          <img src={getData.TitleImg} alt="" />
                        </div>
                        <div>
                        {/* <h2>{getData.courseName}</h2> */}
                    <p className="mt-4">
                      {getData.description}
                    </p>
                    {/* <p>
                      Quis autem vel eum iure reprehenderit qui in ea voluptate
                      velit esse quam nihil molestiae consequatur, vel illum qui
                      dolorem eum fugiat quo. nim ad minima veniam, quis nostrum
                      exercitationem ullam corporis suscipit laboriosam.
                    </p> */}
                        </div>
                      </div>
                      <div className="col-md-6 order-md-1">
                      <div className="row service_advantage " bis_skin_checked={1}>
                    <div className="" bis_skin_checked={1}>
                      <div className="service_text_left" bis_skin_checked={1}>
                        <h2 className="course-title-md">{getData.CourseTitleOne.Titlename} </h2>
                        {/* <p>
                          Lorem ipsum dolor sit amet consectetur adipiscing elit
                          sed do eiusmod tempor incididunt ut labore et dolore
                          magna aliqua unt in culpa qui officia deserunt mollit
                          anim est laborum..
                        </p> */}
                        {/* <br /> */}
                        <ul className="row row-cols-2">
                          {getData.CourseTitleOne.List.map((v, i) => {
                            return (
                              <li>
                                <i className=" col fas fa-check-circle" /> 
                                {v}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    {/* <div className="col-md-5" bis_skin_checked={1}>
                      <div className="image_thumb" bis_skin_checked={1}>
                        <img src={getData.CourseTitleOne.img} alt="" />
                      </div>
                    </div> */}
                  </div>
                  {getData.CourseTitleTwo ? (
                    <div
                      className=" service_advantage "
                      bis_skin_checked={1}
                    >
                      <div className="" bis_skin_checked={1}>
                        <div className="service_text_left" bis_skin_checked={1}>
                          <h2>{getData.CourseTitleTwo.Titlename} </h2>
                       
                          <ul className="row row-cols-2">
                            {getData.CourseTitleTwo.List.map((v, i) => {
                              return (
                                <li>
                                  <i className=" col fas fa-check-circle" /> 
                                  {v}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <p></p>
                  )}
                      </div>
                    </div>
                    
                  </div>
                  
                 

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     </section>
    </>
  );
};

export default Courses;
