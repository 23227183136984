import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';
import Star from "../Asserts/star.webp";
const SliderTestimonial = () => {
  return (
    <>
       <Swiper
        slidesPerView={1}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="item" bis_skin_checked={1}>
  <div className="testimonial-block-outer clearfix" bis_skin_checked={1}>
    <div className="client-img" bis_skin_checked={1}>
      <img src="https://www.w3schools.com/w3css/img_avatar3.png" alt="Makeover In Andheri" />
    </div>
    <div className="name" bis_skin_checked={1}>
      Ms. Kalyani
      <br />
      <span>student</span>
    </div>
    <div className="rating" bis_skin_checked={1}>
      <img src={Star} alt="Makeover  In Andheri" />
    </div>
    <div className="text-box" bis_skin_checked={1}>
      <p>
      Attending Makeover Academy was an absolute game-changer for me! The instructors were incredibly knowledgeable and supportive, guiding me through every step of my journey in the beauty industry. I can't thank them enough for helping me refine my skills and build the confidence to pursue my dream career <br />
      </p>
    </div>
  </div>
</div>

        </SwiperSlide>
        <SwiperSlide>
        <div className="item" bis_skin_checked={1}>
  <div className="testimonial-block-outer clearfix" bis_skin_checked={1}>
    <div className="client-img" bis_skin_checked={1}>
      <img src="https://www.w3schools.com/w3css/img_avatar3.png" alt="Makeover Academy In Andheri" />
    </div>
    <div className="name" bis_skin_checked={1}>
      Ms. Pooja
      <br />
      <span>student</span>
    </div>
    <div className="rating" bis_skin_checked={1}>
      <img src={Star} alt="Makeover In Andheri" />
    </div>
    <div className="text-box" bis_skin_checked={1}>
      <p>
      I recently completed a course at Makeover Academy, and I couldn't be happier with my experience. The curriculum was comprehensive, covering everything from basic techniques to advanced trends. The hands-on approach allowed me to practice and perfect my skills, and I'm now equipped with the knowledge and confidence to succeed in the competitive beauty industry. <br />
      </p>
    </div>
  </div>
</div>

        </SwiperSlide>
        <SwiperSlide>
        <div className="item" bis_skin_checked={1}>
  <div className="testimonial-block-outer clearfix" bis_skin_checked={1}>
    <div className="client-img" bis_skin_checked={1}>
      <img src="https://www.w3schools.com/w3css/img_avatar3.png" alt=" Academy In Andheri" />
    </div>
    <div className="name" bis_skin_checked={1}>
      Ms. Roshni
      <br />
      <span>student</span>
    </div>
    <div className="rating" bis_skin_checked={1}>
      <img src={Star} alt="Makeover Academy" />
    </div>
    <div className="text-box" bis_skin_checked={1}>
      <p>
      Makeover Academy exceeded all my expectations! The instructors were not only experts in their field but also passionate about helping students succeed. The supportive learning environment made it easy to ask questions and seek guidance, and I left feeling inspired and motivated to pursue my goals in the beauty industry <br />
      </p>
    </div>
  </div>
</div>

        </SwiperSlide>
        <SwiperSlide>
        <div className="item" bis_skin_checked={1}>
  <div className="testimonial-block-outer clearfix" bis_skin_checked={1}>
    <div className="client-img" bis_skin_checked={1}>
      <img src="https://www.w3schools.com/w3css/img_avatar3.png" alt=" Academy In Andheri" />
    </div>
    <div className="name" bis_skin_checked={1}>
      Ms. Rani
      <br />
      <span>student</span>
    </div>
    <div className="rating" bis_skin_checked={1}>
      <img src={Star} alt="Makeover  In Andheri" />
    </div>
    <div className="text-box" bis_skin_checked={1}>
      <p>
      Makeover Academy truly stands out for its dedication to excellence. From the state-of-the-art facilities to the personalized support from instructors, every aspect of my experience was top-notch. I'm grateful for the invaluable skills and connections I gained during my time at the academy, and I would highly recommend it to anyone looking to kickstart their career in beauty <br />
      </p>
    </div>
  </div>
</div>

        </SwiperSlide>
      </Swiper> 
    </>
  )
}

export default SliderTestimonial