import React,{useLayoutEffect} from 'react'
import Breadcrums from './Breadcrums'
import { Image } from 'antd';
import GalleryData from "../Asserts/GalleryData";
import MetaTags from 'react-meta-tags';

const Gallery = () => {
  useLayoutEffect(()=>{
    window.scrollTo(0,0)
  })
  return (
    <>
     <MetaTags>
      <title>Top Makeover Academy Near Andheri | Pratima Makeover</title>
        <meta title="Top Makeover Academy Near Andheri | Pratima Makeover" />
        <meta
          name="description"
          content="Discover Andheri's top makeover academy, Pratima Makeover, offering expert beauty transformations with precision and care."
        />
        <meta
          name="keywords"
          content="Makeover Academy In Andheri, Best Makeover Academy In Andheri, Makeover Service In Andheri,Makeup In Andheri,Nail Art In Andheri,Makeover Courses In Andheri,Hair Dressing Course In Andheri,Beautician Course In Andheri,Bridal Makeover Course In Andheri,Saree Draping Course In Andheri,Hair Styling Course In Andheri,Top Makeover Academy Near Andheri,Makeover Academy Near Andheri"
        />
        <link rel="canonical" href="https://pratimamakeovers.in/top-makeover-academy-near-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Top Makeover Academy Near Andheri | Pratima Makeover" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://pratimamakeovers.in/"/>
        <meta property="og:description" content="Discover Andheri's top makeover academy, Pratima Makeover, offering expert beauty transformations with precision and care." />
        <meta property="og:image" content="https://pratimamakeovers.in/static/media/JMS.7493f202fc387288d7c0.png" />       
      </MetaTags>
      <p className='d-none'>
      Welcome to our Gallery page, where we invite you to explore the stunning transformations and exquisite beauty creations crafted at our Pratima Makeover . Immerse yourself in a visual journey showcasing the talent, creativity, and dedication of our team of skilled professionals.

From captivating bridal makeovers to intricate hairstyling masterpieces, our gallery captures the essence of our commitment to excellence in every service we offer. Browse through our collection of before-and-after photos, showcasing the remarkable transformations achieved through our beauty and bridal courses.

Witness the artistry and precision displayed in each makeup application, highlighting the unique features and enhancing natural beauty. Marvel at the intricate hairstyles, from elegant updos to cascading curls, designed to complement every bride's individual style.

As you peruse our gallery, you'll discover the joy and confidence radiating from our satisfied customers, each one a testament to the personalized care and attention to detail we provide. Whether you're seeking inspiration for your own makeover or simply appreciating the artistry of our work, our gallery offers a glimpse into the world of beauty and transformation.

Located conveniently for our nearby customers, our salon is more than just a destination for beauty services – it's a sanctuary where dreams come to life. Join us at our Makeover Academy and Salon, where every image tells a story of beauty, passion, and empowerment.

      </p>
    <Breadcrums bannerTitle="Top Makeover Academy Near Andheri" bannerSubTitle="Gallery" />
    <section>
        <div className='container mb-5 mt-5'>
        <Image.PreviewGroup >
        <div className="row">

      {GalleryData.map(item => (
       <div className='col-lg-3 col-sm-2 my-2 gallery-images-cards'>
       <Image
       className=''
          key={item.id}
          // width={300}
          // marginBottom={10}
          // height={400} // Add your desired fixed height
          style={{borderRadius:30}}
          // style={{  borderRadius: 30, paddingLeft:10,paddingRight:10,width:"250px",paddingBottom:10 }} // Adjust margin and border-radius as needed
          src={item.src}
        />
       </div>
      ))}
        </div>
    </Image.PreviewGroup>
        </div>
    </section>
    </>
  )
}

export default Gallery