import React, { useRef, useState } from "react";
import Breadcrums from "../Pages/Breadcrums";
import "../Css/Contact.css";
import MetaTags from "react-meta-tags";
import $ from "jquery";

const Contact = () => {
  // email
  const formRef = useRef(null);
  const [custName, setcustName] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custMessage, setCustMessage] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [formError, setFormError] = useState(false);

  const ContactEnquiry = (e) => {
    e.preventDefault();

    // Validate form fields
    if (!custName || !custMessage || !custEmail || !custSubject) {
      setFormError(true);
      return;
    }

    // Reset form error state
    setFormError(false);

    // Your form submission logic goes here
    var body = `<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#659494">Pratima Makeover Enquiry</h2><p>Hello</p><p>Please check your enquiry which generated from the website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left"><strong>Name:</strong></td><td style="text-align:left">${custName}</td></tr><tr><td style="text-align:left"><strong>Email:</strong></td><td style="text-align:left">${custEmail}</td></tr><tr><td style="text-align:left"><strong>Subject:</strong></td><td style="text-align:left">${custSubject}</td></tr><tr><td style="text-align:left;vertical-align:top"><strong>Message:</strong></td><td style="text-align:left">${custMessage}</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Pratima Makeover</p></div></body></html>`;

    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "pratimanikam17@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "pratimamakeover",
        accountLeadSource: "https://skdm.in",
        accountLeadName: custName,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
      }
    );

    // Reset the form
    if (formRef.current) {
      formRef.current.reset();
    }

    alert("Your Form has Submitted. Our team will contact you soon.");
  };
  return (
    <>
      <MetaTags>
        <title>Makeover Academy Near Andheri | Pratima Makeover</title>
        <meta title="Makeover Academy Near Andheri | Pratima Makeover" />
        <meta
          name="description"
          content="Transform yourself at Makeover Academy near Andheri, where Pratima Makeover brings out your best with professional expertise."
        />
        <meta
          name="keywords"
          content="Makeover Academy In Andheri, Best Makeover Academy In Andheri, Makeover Service In Andheri,Makeup In Andheri,Nail Art In Andheri,Makeover Courses In Andheri,Hair Dressing Course In Andheri,Beautician Course In Andheri,Bridal Makeover Course In Andheri,Saree Draping Course In Andheri,Hair Styling Course In Andheri,Top Makeover Academy Near Andheri,Makeover Academy Near Andheri"
        />
        <link
          rel="canonical"
          href="https://pratimamakeovers.in/makeover-academy-near-andheri"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Makeover Academy Near Andheri | Pratima Makeover"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://pratimamakeovers.in/makeover-academy-near-andheri"
        />
        <meta
          property="og:description"
          content="Transform yourself at Makeover Academy near Andheri, where Pratima Makeover brings out your best with professional expertise."
        />
        <meta
          property="og:image"
          content="https://pratimamakeovers.in/static/media/JMS.7493f202fc387288d7c0.png"
        />
      </MetaTags>
      <Breadcrums
        bannerTitle="Makeover Academy Near Andheri"
        bannerSubTitle="Contact Us"
      />
      <p className="d-none">
        Welcome to our Pratima makeover, a Makeover Academy Near Andheri. In our
        contact us page, you'll find your gateway to connecting with our salon
        and experiencing the pinnacle of beauty excellence. Whether you're ready
        to book an appointment, inquire about our services, or simply want to
        learn more about what we offer, we're here to assist you every step of
        the way. Contacting us is easy! You can reach out to our friendly and
        knowledgeable team via phone, email, or through the convenient contact
        form on this page. Our dedicated staff is committed to providing prompt
        and personalized assistance, ensuring that all your questions are
        answered and your needs are met with the utmost care and attention to
        detail. If you prefer speaking with someone directly, feel free to give
        us a call during our regular business hours. Our receptionists are
        standing by to assist you with scheduling appointments, discussing our
        services, or addressing any concerns you may have. Makeover Academy Near
        Andheri strives to make the booking process as seamless and hassle-free
        as possible, so you can focus on enjoying your salon experience. For
        those who prefer to communicate via email, you can reach us at [email
        address]. Simply send us a message with your inquiries, and one of our
        team members will respond promptly to assist you. Whether you're looking
        to schedule a consultation, request pricing information, or provide
        feedback on your recent visit, we're here to help and eager to hear from
        you. Additionally, you can use the contact form on this page to send us
        a message directly from our website. Simply fill out the required fields
        with your name, email address, and message, and we'll get back to you as
        soon as possible. Whether you're a new client or a returning customer,
        we value your input and are committed to providing exceptional service
        every step of the way. Thank you for considering our Makeover Academy
        Near Andheri for your beauty needs. We look forward to hearing from you
        soon and welcoming you into our salon family.
      </p>
      <section className="contact_us_area">
        <div className="container" bis_skin_checked={1}>
          <div className="contact_us_inner" bis_skin_checked={1}>
            <div className="sec-title text-center" bis_skin_checked={1}>
              {/*   <h1>Feel Free to drop us a Message</h1> */}
              {/*  <p>Lorem ipsum dolor sit amet cons ectetur elit Vestibulum nec odios Suspe ndisse cursus mal suada faci lisis consectetur elit Vesti bulum nec odio ipsum ons ectetur elit. </p> */}
            </div>
            <div className="row" bis_skin_checked={1}>
              <div className="col-lg-4 col-md-4" bis_skin_checked={1}>
                <div className="contact_us_details" bis_skin_checked={1}>
                  <div className="row" bis_skin_checked={1}>
                    <div className="c_details_item" bis_skin_checked={1}>
                      <div className="media d-flex" bis_skin_checked={1}>
                        <div className="media-left" bis_skin_checked={1}>
                          <i className="fa fa-map-marker-alt" />
                        </div>
                        <div className="media-body" bis_skin_checked={1}>
                          <p>
                            {" "}
                            3/19 Mahadev Bhavan, New Nagardas Road, Near Pinky
                            Cinema, Andheri East, Mumbai, India, Maharashtra
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="c_details_item" bis_skin_checked={1}>
                      <div className="media d-flex" bis_skin_checked={1}>
                        <div className="media-left" bis_skin_checked={1}>
                          <i className="fa fa-envelope" />
                        </div>
                        <div className="media-body" bis_skin_checked={1}>
                          <a href="#">Pratimanikam17@gmail.com</a>
                          {/* <a href="#">support@Lipos.com</a> */}
                        </div>
                      </div>
                    </div>
                    <div className="c_details_item" bis_skin_checked={1}>
                      <div className="media d-flex" bis_skin_checked={1}>
                        <div className="media-left" bis_skin_checked={1}>
                          <i className="fas fa-phone-alt" />
                        </div>
                        <div
                          className="media-body contactUs-page"
                          bis_skin_checked={1}
                        >
                          <a href="#">88796 76606</a>
                          <a href="#">88796 76623</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-8" bis_skin_checked={1}>
                <div className="row" bis_skin_checked={1}>
                  <form
                    className="contact_us_form"
                    method="post"
                    ref={formRef}
                    action=""
                    id="contact-form"
                  >
                    <div className="form-group col-md-12" bis_skin_checked={1}>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Name"
                        onChange={(e) => setcustName(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-12" bis_skin_checked={1}>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email"
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-12" bis_skin_checked={1}>
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        placeholder="Subject"
                        onChange={(e) => setCustSubject(e.target.value)}
                      />
                    </div>
                    <div className="form-group col-md-12" bis_skin_checked={1}>
                      <textarea
                        className="form-control"
                        name="message"
                        id="message"
                        rows={1}
                        placeholder="Message"
                        defaultValue={""}
                        onChange={(e) => setCustMessage(e.target.value)}
                      />
                    </div>
                    {formError && (
                      <p style={{ color: "red" }}>
                        Please fill in all required fields
                      </p>
                    )}
                    <div className="form-group col-md-12" bis_skin_checked={1}>
                      <button
                        type="submit"
                        value="submit"
                        className="theme-btn-2 btn-md "
                        onClick={ContactEnquiry}
                      >
                        Submit now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="mx-5">
<iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d805196.5079038654!2d144.4927068996644!3d-37.97015422631366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad646b5d2ba4df7%3A0x4045675218ccd90!2sMelbourne+VIC%2C+Australia!5e0!3m2!1sen!2sin!4v1495807350985"
  width="100%"
  height={450}
  frameBorder={0}
  allowFullScreen=""
/>

</section> */}
    </>
  );
};

export default Contact;
