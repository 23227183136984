import './App.css';
import '../src/Css/Responsive.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import Home from './Components/Home';
import Contact from './Components/Contact';
import Header from './Components/Header';
import Footer from './Components/Footer';
import AboutUs from './Components/AboutUs';
import Courses from './Pages/Courses';
import CoursesData from "./Asserts/CoursesData";
import Services from './Pages/Services';
import Gallery from './Pages/Gallery';
import ErrorPage from './Pages/ErrorPage';

function App() {
  return (
    <>
    <BrowserRouter>
    <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/makeover-academy-near-andheri' element={<Contact />} />
        <Route path='/best-makeover-academy-andheri' element={<AboutUs />} />
        <Route path='/makeover-service-andheri' element={<Services />} />
        <Route path='/top-makeover-academy-near-andheri' element={<Gallery />} />
        <Route path='/*' element={<ErrorPage />} />
        {CoursesData.map((v,i)=>{
          return(
            <>
            <Route path={v.slugs} element={<Courses/>} />
            </>
          )
        })}
      </Routes>
      <Footer />
    </BrowserRouter>

    </>
  );
}

export default App;
